import axios from "axios";

function getKeywords(id, wl_code) {
  let wlCode = axios.defaults.headers.wl_code;
  if (wl_code) {
    axios.defaults.headers.wl_code = wl_code;
  }
  const response = axios.get(`error-translation/${id}`);
  axios.defaults.headers.wl_code = wlCode;
  return response;
}

function editKeywords(id, data, wl_code) {
  let wlCode = axios.defaults.headers.wl_code;
  if (wl_code) {
    axios.defaults.headers.wl_code = wl_code;
  }
  const response = axios.put(`error-translation/${id}`, data);
  axios.defaults.headers.wl_code = wlCode;
  return response;
}

function addNewKeywordTranslation(data) {
  return axios.post('error-translation/add-new-keyword',data);
}

export default {
  getKeywords,
  editKeywords,
  addNewKeywordTranslation
};
