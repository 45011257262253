<template>
  <div>
    <b-card>
      <b-row>
        <b-col v-if="wl_code == 1" cols="5" md="5" class="mb-md-1 mb-1">
          <label>Filter by WhiteLabel</label>
          <v-select
            v-model="wlCode"
            :options="whitelabelList"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
            :reduce="val => val.value"
            placeholder="Select Whitelabel"
            :clearable="true"
          />
        </b-col>
        <b-col md="5">
          <validation-provider
            #default="{ errors }"
            name="Language"
            rules="required"
          >
            <b-form-group
              class="md-2"
              label="Select Languages"
              label-for="mc-select-language"
              :state="errors.length > 0 ? false : null"
            >
              <v-select
                v-model="keywordForm.selectedLanguage"
                :options="keywordForm.selectedLanguages"
                class="language"
                placeholder="Select Languages"
                :state="errors.length > 0 ? false : null"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :reduce="val => val.value"
                :clearable="false"
                @input="getLanguageId($event)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="2">
          <label for="" class="d-block invisible">Add Keyword</label>
          <b-button
            variant="primary"
            class="float-right"
            v-b-modal.modal-add-keyword
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          >
            Add Keyword
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <div v-if="!items && !contentLoading" class="demo-spacing-0">
      <b-alert variant="primary" show>
        <div class="alert-body">
          <span><strong>No Error Transaltion Found!</strong></span>
        </div>
      </b-alert>
    </div>

    <b-card class="translation-card" v-if="items">
    <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form class="mt-2 keyword_form" @submit.prevent>
          <div class="top col-12 translation_box">
            <b-row
              v-for="(item, index) in items"
              :key="index"
            >
              <b-col>
                <div>
                  {{ item.label }}
                </div>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group :label="`${index}`" label-for="mc-country">
                  <validation-provider
                    #default="{ errors }"
                    :name="`${index}`"
                    rules="required"
                  >
                    <b-form-input
                      v-model="item.value"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="`${item.label}`"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </div>

          <div class="btm translation_sub_box">
            <b-row class="float-right">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                :disabled="loading"
                @click="editKeywords"
              >
                Save changes
                <div v-if="loading" class="spinner-border spinner-border-sm" />
              </b-button>
            </b-row>
          </div>
        </b-form>
      </validation-observer>
    </b-card>
    <div v-if="contentLoading">
      <Loader />
    </div>
    <b-modal
      id="modal-add-keyword"
      title="Add New Keyword Translation"
      cancel-title="Close"
      ok-title="Save"
      size="lg"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @ok="AddNewKeywordTranslation"
      @cancel="resetAddForm()"
      @close="resetAddForm()"
    >
      <validation-observer ref="keywordAddRules">
        <b-form @submit.prevent>
          <b-row col>
            <b-col md="12">
              <b-col md="12">
                <b-form-group label="Key" label-for="mc-Key">
                  <validation-provider
                    #default="{ errors }"
                    name="Key"
                    rules="required|alphaNumeric|noWhiteSpaces"
                  >
                    <b-form-input
                      v-model="addKewordForm.key"
                      class="Key"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Key"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group label="Label" label-for="mc-Label">
                  <validation-provider
                    #default="{ errors }"
                    name="Label"
                    rules="required"
                  >
                    <b-form-input
                      v-model="addKewordForm.label"
                      class="Label"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Label"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group label="Value" label-for="mc-Value">
                  <validation-provider
                    #default="{ errors }"
                    name="Value"
                    rules="required"
                  >
                    <b-form-textarea
                      v-model="addKewordForm.value"
                      class="Value"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Value"
                      rows="3"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BTabs, BTab, BFormGroup, BRow, BCol, BCard, BFormInput, BButton, BForm, BAlert, BModal, VBModal, BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "@core/utils/validations/validations";
import Ripple from "vue-ripple-directive";
import languageService from "@/services/language/language.service";
import { LanguageEnum, resConditionCheck, } from "@/helpers/constant";
import errorResponseHandler from "@/services/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import translationService from "@/services/error-validation-translation/errorValidationTranslation.service";
import Loader from "@/layouts/skeloton-loader/Loader.vue";
import whitelabelService from '@/services/whitelabel/whitelabel.service';
import axios from 'axios';

export default {
  components: {
    BTabs,
    BTab,
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    Loader,
	  BFormInput,
    BButton,
    BForm,
    BAlert,
    BModal,
    VBModal, 
    BFormTextarea,
  },
  data() {
    return {
      whitelabelList: [{ label: 'Beontrips', value: 1 }],
      wl_code: axios.defaults.headers.wl_code,
      wlCode: 1,
      contentLoading: true,
      keywordForm: {
        selectedLanguages: [],
        value: [],
        selectedLanguage: LanguageEnum.English || ""
      },
      languageId: LanguageEnum.English,
      idx: "",
      keyword: {
        keyword_value: ""
      },
      items: null,
      loading: false,
      required,
      addKewordForm: {
        key: "",
        label: "",
        value: "",
      },
    };
  },
  directives: {
    Ripple,
    "b-modal": VBModal
  },

  watch: {
    wlCode: {
      async handler(val) {
        if (val === null) {
          this.wlCode = 1;
        }
        console.log("valalvlalvav",val)
        await this.getLanguageDropdown();
        await this.getKeywords(this.languageId);
      },
    },
  },

  async mounted() {
    await this.getLanguageDropdown();
    await this.getKeywords(this.languageId);
    if(this.wl_code == 1) {
      await this.getWhitelabelList();
    }
  },

  methods: {
    async getLanguageDropdown() {
      try {
        const WLCODE = (localStorage.getItem("signinWLAdmin") && localStorage.getItem("whitelabel_code")) ? localStorage.getItem("whitelabel_code") : (this.wl_code !== 1) ? this.wl_code : this.wlCode; 
        const res = await languageService.getLanguageDropdown(WLCODE);
        if (resConditionCheck(res.status) && res.data.data) {
          this.keywordForm.selectedLanguages = res.data.data.map(e => ({
            label: e.name,
            value: e.id
          }));
          // if(this.wlCode !== 1) {
            const lang = res.data.data.find(e=> e.iso_1Code == 'EN');
            this.languageId = lang ? lang.id : 1;
            this.keywordForm.selectedLanguage = this.languageId;
            console.log("langauge id", this.languageId)
          // }
        }
      } catch (error) {
        console.log("this.languageId",error)
        const errorData = errorResponseHandler(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger"
          }
        });
      }
    },

    async getWhitelabelList() {
      this.isLoaded = false; 
      const payload = { no_of_result: 0, page: 1, order: "ASC", order_by: 'name' };
      await whitelabelService.getWhitelableList(payload)
      .then(res=>{
        if (resConditionCheck(res.status) && res.data.data) {
          this.isLoaded = true
          this.whitelabelList = res.data.data.map(item => {
            // if(!item.isDeleted) {
              return {
                label: item.name,
                value: item.id
              }
            // }
          });
          this.whitelabelList.push({ label: 'Beontrips', value: 1 })
        }
      })
      .catch(error=>{
        const errorData = errorResponseHandler(error);
        this.$toast({
          component: ToastificationContent,
          props: {
          title: errorData,
          icon: "X-CircleIcon",
          variant: "danger"
          }
        });
      })
    },

    async getKeywords(id) {
      this.contentLoading = true;
      try {
        const WLCODE = (localStorage.getItem("signinWLAdmin") && localStorage.getItem("whitelabel_code")) ? localStorage.getItem("whitelabel_code") : (this.wl_code !== 1) ? this.wl_code : this.wlCode; 
        const res = await translationService.getKeywords(id, WLCODE);
        if (resConditionCheck(res.status) && res.data.data) {
          this.contentLoading = false;
          this.idx = res.data.data && res.data.data.length ? res.data.data[0].id : '';
          this.items = res.data.data && res.data.data.length ? res.data.data[0].translation : res.data.data;
        }
      } catch (error) {
        const errorData = errorResponseHandler(error);
        this.items = null;
        this.contentLoading = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger"
          }
        });
      }
    },

    getLanguageId(event) {
      this.getKeywords(event);
    },

    editKeywords() {
			this.$refs.simpleRules.validate().then(success => {
				if (success) {
          const WLCODE = (localStorage.getItem("signinWLAdmin") && localStorage.getItem("whitelabel_code")) ? localStorage.getItem("whitelabel_code") : (this.wl_code !== 1) ? this.wl_code : this.wlCode; 
					this.loading = true;
					translationService
						.editKeywords(this.idx, {
							translation: btoa(
								unescape(encodeURIComponent(JSON.stringify(this.items)))
							)
						}, WLCODE)
						.then(res => {
							if (resConditionCheck(res.status) && res.data.data) {
								setTimeout(() => {
									this.loading = false;
								}, 3000);
								this.$toast({
									component: ToastificationContent,
									props: {
										title: res.data.message,
										icon: "CheckIcon",
										variant: "success"
									}
								});
							}
						})
						.catch(error => {
							setTimeout(() => {
								this.loading = false;
							}, 3000);
							const errorData = errorResponseHandler(error);
							this.$toast({
								component: ToastificationContent,
								props: {
									title: errorData,
									icon: "X-CircleIcon",
									variant: "danger"
								}
							});
						});
				}
			});
		},
    async AddNewKeywordTranslation(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.keywordAddRules.validate().then(async (success) => {
        if (success) {
          this.loading = true;
          await translationService
            .addNewKeywordTranslation(this.addKewordForm)
            .then(async(res) => {
              if (resConditionCheck(res.status) && res.data) {
                this.loading = false;
                this.$nextTick(() => {
                  this.$emit("on-submit");
                  this.$bvModal.hide("modal-add-keyword");
                  this.resetAddForm();
                });
                await this.getKeywords(this.languageId);
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: res.data.message,
                    icon: "X-CircleIcon",
                    variant: "success",
                  },
                });
              }
            })
            .catch((error) => {
              this.loading = false;
              const errorData = errorResponseHandler(error);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorData,
                  icon: "X-CircleIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },
    resetAddForm() {
      this.addKewordForm.page_name = '';
      this.addKewordForm.key = '';
      this.addKewordForm.label = '';
      this.addKewordForm.value = '';
    }
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style scoped>
.translation-card {
  height: calc(128vh - 360px);
}
.translation_box {
	overflow-x: hidden;
}
.translation_sub_box {
	position: relative;
}
.keyword_form {
	height: calc(100% - 86px);
	display: flex;
	justify-content: space-between;
	flex-flow: column;
}
</style>